<template>
  <form @submit.prevent="createEvent()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.event_type_id.$error }">
            <ion-label position="floating">Event Type</ion-label>
            <ion-select v-model="event_type_id">
              <ion-select-option
                v-for="eventType of eventTypes"
                :key="eventType.id"
                :value="eventType.id"
              >
                {{ eventType.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.name.$error }">
            <ion-label position="floating">Name</ion-label>
            <ion-input type="text" v-model="name"></ion-input>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.description.$error }">
            <ion-label position="floating">Description</ion-label>
            <ion-textarea type="text" v-model="description"></ion-textarea>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.event_from_date.$error }">
            <ion-label position="floating">Event from date</ion-label>
            <ion-datetime type="text" v-model="event_from_date"></ion-datetime>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.event_to_date.$error }">
            <ion-label position="floating">Event to date</ion-label>
            <ion-datetime type="text" v-model="event_to_date"></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label>Send app notification</ion-label>
            <ion-checkbox
              slot="end"
              style="margin-bottom: 0; margin-top: 0"
              v-model="app_notification"
            ></ion-checkbox>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonIcon,
  IonButton,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonText,
  IonCheckbox,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import {
  required,
  maxLength,
  integer,
  helpers,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import moment from "moment";

export default {
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonIcon,
    IonButton,
    IonInput,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonText,
    IonCheckbox,
  },
  data() {
    return {
      event_type_id: null,
      name: null,
      description: null,
      event_from_date: null,
      event_to_date: null,
      app_notification: false,

      validationError: null,

      alertCircleOutline,
      alertCircleSharp,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      event_type_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },
      name: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      description: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      event_from_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      event_to_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
        // Custom Validator
        minValue: helpers.withMessage(
          "cannot be less than from date",
          function minValue(val) {
            return new Date(val) >= new Date(this.event_from_date);
          }
        ),
      },
    };
  },

  computed: {
    eventTypes() {
      return this.$store.getters["event/types"];
    },
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async createEvent() {
      if (this.event_to_date === null) {
        this.event_to_date = this.event_from_date;
      }

      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        event_type_id: this.event_type_id,
        name: this.name,
        description: this.description,
        event_from_date: moment(this.event_from_date).format("YYYY-MM-DD"),
        event_to_date: moment(this.event_to_date).format("YYYY-MM-DD"),
        app_notification: this.app_notification,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("event/createResource", data);
        spinner.dismiss();

        this.name = null;
        this.description = null;

        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async fetcheventTypes() {
      await this.$store.dispatch("event/types");
    },
  },

  created() {
    this.fetcheventTypes();
  },
};
</script>